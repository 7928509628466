function NotFound() {
  
  return (  
    <div className="NotFound">
      NOT FOUND????
    </div>
  )
}

export default NotFound
